import React, { useRef } from "react";
import "./forgotPassword.css";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { useMutation, gql } from "@apollo/client";

interface IProps { }

const FORGOT_PASSWORD_GQL = gql`
  mutation forgotPasswordAffiliatePartner($email: String!) {
    forgotPasswordAffiliatePartner(email: $email)
  }
`;

const ForgotPassword: React.FC<IProps> = () => {
  //const [showError, setShowError] = useState(false);
  const loginTextBox = useRef<HTMLInputElement | null>(null);
  const [ForgotPassword, { data }] = useMutation(FORGOT_PASSWORD_GQL);

  const handleForgotPasswordClicked = () => {
    ForgotPassword({
      errorPolicy: "all",
      variables: {
        email: loginTextBox.current?.value,
      },
    });
  };

  if (data && data.forgotPasswordAffiliatePartner) {
    return (
      <div className="forgotPassword">
        <div className="logo"></div>
        <div className="background"></div>
        <div className="container">
          <div className="boxContainer">
            <div className="textContainer">
              <h1>Glemt adgangskode</h1>
              <p>Kontrollér din e-mail for at angive din nye adgangskode.</p>
              <p>
                Hvis du ikke modtager en e-mail inden for få minutter, har du enten angivet en e-mailadresse, der ikke svarer til den, der er
                tilknyttet din konto, eller e-mailen er blevet opfanget af dit spamfilter.
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="forgotPassword">
        <div className="logo">Settle</div>
        <div className="background"></div>
        <div className="container">
          <div className="boxContainer">
            <div className="textContainer">
              <h1>Glemt adgangskode</h1>

              <TextField fullWidth id="username" label="E-mailadresse" variant="standard" defaultValue={""} inputRef={loginTextBox} />
              <br />
            </div>
            <div className="buttonContainer">
              <Button
                onClick={() => {
                  handleForgotPasswordClicked();
                }}
                className="button"
                variant="contained"
              >
                Glemt adgangskode
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }
};
export default ForgotPassword;
