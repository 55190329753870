import React from 'react';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n'; // path to your i18n config

import * as ReactDOM from "react-dom/client";
import { AffiliatePartner } from "./providers/affiliatePartner";
import ProtectedRoutes from "./providers/protectedRoutes";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { ApolloClient, InMemoryCache, ApolloProvider, createHttpLink } from "@apollo/client";
import "./index.css";
import Login from "./pages/login/login";
import Error from "./pages/error/error";
import LeadList from "./pages/leadList/leadList";
import ForgotPassword from "./pages/forgotPassword/forgotPassword";
import { setContext } from "@apollo/client/link/context";

import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import ChangePassword from "./pages/changePassword/changePassword";
import LeadInfo from "./pages/leadInfo/leadInfo";
import PartnerAgreements from './pages/partnerAgreements/partnerAgreements';

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_BACKEND_URL,
});

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem("id_token");
  if (token) {
    return {
      headers: {
        ...headers,
        authorization: "Bearer " + token,
      },
    };
  } else {
    return {
      headers: {
        ...headers,
      },
    };
  }
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),

});

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

const loginRouter = createBrowserRouter([
  {
    path: "newpassword/:affiliatePartnerId/:token",
    element: <ChangePassword />,
  },
  {
    path: "/forgotpassword",
    element: <ForgotPassword />,
  },
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/login/:t",
    element: <Login />,
  },
  {
    path: "/",
    element: <Login />,
  },
  {
    element: <ProtectedRoutes />,
    children: [
      {
        path: "/leads",
        element: <LeadList />,
      },
      {
        path: "/lead/:id",
        element: <LeadInfo />,
      },
      {
        path: "/partnerAgreements",
        element: <PartnerAgreements />,
      }
    ],
  },
  {
    path: "*",
    element: <Error errorCode={404} />,
  },
]);

root.render(
  <ApolloProvider client={client}>
    <AffiliatePartner key={"affiliatePartner"}>
      <I18nextProvider i18n={i18n}>
        <RouterProvider router={loginRouter} ></RouterProvider>
      </I18nextProvider>
    </AffiliatePartner>
  </ApolloProvider>
);
