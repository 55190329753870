import React, { useEffect, useState } from "react";

import Error from "../error/error";
//import state, { StateParameter } from "../../utils/state";
import Grid from "@mui/material/Unstable_Grid2";
import "./leadList.css";
import { datastore } from "../../datastore";
import { useLazyQuery, gql } from "@apollo/client";
import { DataGrid, GridColDef, GridToolbar } from '@mui/x-data-grid';
import { useNavigate } from "react-router-dom";
import Paper from '@mui/material/Paper';
import { Card, CardContent, Select } from "@mui/material";
import DatePicker, { registerLocale } from 'react-datepicker';
import { da as daLocale, id } from 'date-fns/locale';


import "react-datepicker/dist/react-datepicker.css";

//import FormControlLabel from "@mui/material/FormControlLabel";
import TopMenu, { TopMenuItem, viewType } from "../../components/TopMenu/TopMenu";
import Logo from "../../components/Logo/Logo";

const GET_ORDERS = gql`
  query leadsByAffiliatePartner($affiliatePartnerId: ID!,$toDate: String!, $fromDate: String!) {
    leadsByAffiliatePartner(affiliatePartnerId: $affiliatePartnerId,toDate: $toDate, fromDate: $fromDate) {
      id
      externalOrderId
      status
      productName
	    moveToAddress
      partnerName
      createdAt
    }
  }
`;

const GET_ORDERS_REALESTATEBROKER = gql`
  query leadsByRealEstateBroker($realEstateBrokerId: ID!,$toDate: String!, $fromDate: String!) {
    leadsByRealEstateBroker(realEstateBrokerId: $realEstateBrokerId,toDate: $toDate, fromDate: $fromDate) {
      id
      externalOrderId
      status
      productName
	    moveToAddress
      partnerName
      createdAt
      price
      realEstateBrokerOfficeName
    }
  }
`;

const GET_ORDERS_REALESTATEBROKEROFFICE = gql`
  query leadsByRealEstateBrokerOffice($realEstateBrokerOfficeId: ID!,$toDate: String!, $fromDate: String!) {
    leadsByRealEstateBrokerOffice(realEstateBrokerOfficeId: $realEstateBrokerOfficeId,toDate: $toDate, fromDate: $fromDate) {
      id
      externalOrderId
      status
      productName
	    moveToAddress
      partnerName
      createdAt
      price
      
    }
  }
`;

// Register Danish locale
registerLocale('da', daLocale);

enum RealEstateBrokerView {
  Detailed = 'Detailed',
  Grouped = 'Grouped'
}

const LeadList: React.FC = () => {

  //Set startDate to 1 month ago
  const [startDate, setStartDate] = useState(new Date(new Date().setMonth(new Date().getMonth() - 1)));
  //Set endDate to today
  const [endDate, setEndDate] = useState(new Date());
  const [selectView, setSelectView] = useState(RealEstateBrokerView.Detailed);

  const navigate = useNavigate();
  const [getLeadsByAffiliatePartner, { loading: loadingLeadsByAffiliatePartner, error: errorLeadsByAffiliatePartner, data: dataLeadsByAffiliatePartner }] = useLazyQuery(GET_ORDERS);
  const [getLeadsByRealEstateBroker, { loading: loadingLeadsByRealEstateBroker, error: errorLeadsByRealEstateBroker, data: dataLeadsByRealEstateBroker }] = useLazyQuery(GET_ORDERS_REALESTATEBROKER);
  const [getLeadsByRealEstateBrokerOffice, { loading: loadingRealEstateBrokerOffice, error: errorRealEstateBrokerOffice, data: dataRealEstateBrokerOffice }] = useLazyQuery(GET_ORDERS_REALESTATEBROKEROFFICE);

  useEffect(() => {
    updateData();
  }, [startDate, endDate]);

  if (loadingLeadsByAffiliatePartner || loadingLeadsByRealEstateBroker || loadingRealEstateBrokerOffice) {
    return <div></div>;
  }

  if (errorLeadsByAffiliatePartner || errorLeadsByRealEstateBroker || errorRealEstateBrokerOffice) {
    // return <Error errorCode={500} />;
  }

  /*
  if (!data) {
    return (<Error errorCode={500} />);
  }
  */

  let columns: GridColDef[] = [
    { field: 'realEstateBrokerOfficeName', headerName: 'Butik', minWidth: 200, align: 'left' },
    { field: 'estateAddress', headerName: 'Adresse', minWidth: 300, flex: 1 },
    { field: 'product', headerName: 'Produkt', minWidth: 200, flex: 1 },
    { field: 'price', headerName: 'Beløb', minWidth: 90, flex: 1, align: 'left' },
    { field: 'status', headerName: 'Status', minWidth: 180, flex: 1, align: 'left' },
    { field: 'createdAt', headerName: 'Oprettet', width: 120, align: 'left' },
  ];

  switch (datastore.data.userType) {
    case 'RealEstateBroker':
      if (selectView === RealEstateBrokerView.Detailed) {
        columns = [
          { field: 'realEstateBrokerOfficeName', headerName: 'Butik', minWidth: 200, align: 'left' },
          { field: 'estateAddress', headerName: 'Adresse', minWidth: 300, flex: 1 },
          { field: 'product', headerName: 'Produkt', minWidth: 200, flex: 1 },
          { field: 'price', headerName: 'Beløb', minWidth: 90, flex: 1, align: 'left' },
          { field: 'status', headerName: 'Status', minWidth: 180, flex: 1, align: 'left' },
          { field: 'createdAt', headerName: 'Oprettet', width: 120, align: 'left' },
        ];
      } else {
        //Grouped view
        columns = [
          { field: 'realEstateBrokerOfficeName', headerName: 'Butik', minWidth: 450, flex: 1, align: 'left' },
          { field: 'product', headerName: 'Produkt', minWidth: 200, flex: 1 },
          { field: 'leadsRejectionCount', headerName: 'Antal - afvist', minWidth: 130, align: 'center' },
          { field: 'leadsAcceptanceCount', headerName: 'Antal - accepteret', minWidth: 130, align: 'center' },
          { field: 'leadsWaitingCount', headerName: 'Antal - afventer', minWidth: 130, align: 'center' },
        ];
      }

      break;
    case 'AffiliatePartner':
      columns = [
        { field: 'estateAddress', headerName: 'Adresse', minWidth: 300, flex: 1 },
        { field: 'product', headerName: 'Produkt', minWidth: 200, flex: 1 },
        { field: 'status', headerName: 'Status', minWidth: 180, align: 'left' },
        { field: 'createdAt', headerName: 'Oprettet', width: 120, align: 'left' },
      ];
      break;
    case 'RealEstateBrokerOffice':
      if (selectView === RealEstateBrokerView.Detailed) {
        columns = [
          { field: 'estateAddress', headerName: 'Adresse', minWidth: 300, flex: 1 },
          { field: 'product', headerName: 'Produkt', minWidth: 200, flex: 1 },
          { field: 'price', headerName: 'Beløb', minWidth: 90, flex: 1, align: 'left' },
          { field: 'status', headerName: 'Status', minWidth: 180, flex: 1, align: 'left' },
          { field: 'createdAt', headerName: 'Oprettet', width: 120, align: 'left' },
        ];
      } else {
        //Grouped view
        columns = [
          { field: 'product', headerName: 'Produkt', minWidth: 200, flex: 1 },
          { field: 'leadsRejectionCount', headerName: 'Antal - afvist', minWidth: 130, align: 'center' },
          { field: 'leadsAcceptanceCount', headerName: 'Antal - accepteret', minWidth: 130, align: 'center' },
          { field: 'leadsWaitingCount', headerName: 'Antal - afventer', minWidth: 130, align: 'center' },
        ];
      }
      break;
  }



  const translateStatus = (status: string) => {
    switch (status) {
      case "DECLINED_PROCESSED":
        return "Afvist";
      case "ACCEPTED_PROCESSED":
        return "Godkendt";
      case "UPDATED":
        return "Opdateret";
      case "ACCEPTED":
        return "Oprettet";
      default:
        return "Afventer tilbage melding"
    }
  }

  let data = [] as any;
  if (dataLeadsByAffiliatePartner) {
    data = dataLeadsByAffiliatePartner.leadsByAffiliatePartner
  }
  if (dataLeadsByRealEstateBroker) {
    data = dataLeadsByRealEstateBroker.leadsByRealEstateBroker
  }
  if (dataRealEstateBrokerOffice) {
    data = dataRealEstateBrokerOffice.leadsByRealEstateBrokerOffice
  }

  let rows = [] as any;
  if (selectView === RealEstateBrokerView.Detailed) {
    rows = data.map((lead: any) => {
      return {
        id: lead.id,
        externalId: lead.externalOrderId,
        product: lead.productName,
        estateAddress: lead.moveToAddress,
        status: translateStatus(lead.status),
        createdAt: lead.createdAt.split('T')[0],
        price: (lead.price && lead.status === 'ACCEPTED_PROCESSED') ? lead.price : 0,
        realEstateBrokerOfficeName: lead.realEstateBrokerOfficeName ? lead.realEstateBrokerOfficeName : '',
      }
    });
  } else {
    //Grouped view
    let groupedData: { [key: string]: any } = {};
    // Group by realEstateBrokerOfficeName and product
    data.forEach((lead: any) => {
      let key = lead.realEstateBrokerOfficeName + lead.productName;
      console.log('key:', key);
      if (groupedData[key]) {
        groupedData[key].leadCount++;
        if (lead.status === 'DECLINED_PROCESSED') {
          groupedData[key].leadsRejectionCount++;
        } else if (lead.status === 'ACCEPTED_PROCESSED') {
          groupedData[key].leadsAcceptanceCount++;
        } else {
          console.log('moveToAddress (1):', lead.moveToAddress);
          groupedData[key].leadsWaitingCount++;
        }
        groupedData[key].price += lead.price ? parseInt(lead.price) : 0;
      } else {
        console.log('moveToAddress (2):', lead.moveToAddress);
        groupedData[key] = {
          id: key,
          product: lead.productName,
          leadCount: 1,
          leadsRejectionCount: lead.status === 'DECLINED_PROCESSED' ? 1 : 0,
          leadsAcceptanceCount: lead.status === 'ACCEPTED_PROCESSED' ? 1 : 0,
          leadsWaitingCount: (lead.status !== 'ACCEPTED_PROCESSED' && lead.status !== 'DECLINED_PROCESSED') ? 1 : 0,
          price: lead.price ? parseInt(lead.price) : 0,
          realEstateBrokerOfficeName: lead.realEstateBrokerOfficeName ? lead.realEstateBrokerOfficeName : '',
        };
      }
    });

    // Convert to array
    rows = Object.keys(groupedData).map((key) => ({
      ...groupedData[key],
    }));
  }

  const handleRowClicked = (row: any) => {
    if (datastore.data.userType === 'AffiliatePartner') {
      navigate('/lead/' + row.id);
    }
  }

  const handleStartDateChange = (date: Date) => {
    setStartDate(date);
    setTimeout(() => {
      //   updateData();
    }, 1000);
  }

  const handleEndDateChange = (date: Date) => {
    setEndDate(date);
    setTimeout(() => {
      //  updateData();
    }, 1000);
  }

  const updateData = () => {
    switch (datastore.data.userType) {
      case 'RealEstateBroker':
        getLeadsByRealEstateBroker({
          variables: {
            realEstateBrokerId: datastore.data.affiliatePartnerId,
            toDate: endDate.toISOString().split('T')[0],
            fromDate: startDate.toISOString().split('T')[0],
          },
          fetchPolicy: 'no-cache'
        });

        break;
      case 'AffiliatePartner':
        getLeadsByAffiliatePartner({
          variables: {
            affiliatePartnerId: datastore.data.affiliatePartnerId,
            toDate: endDate.toISOString().split('T')[0],
            fromDate: startDate.toISOString().split('T')[0],
          },
          fetchPolicy: 'no-cache'
        });
        break;
      case 'RealEstateBrokerOffice':
        getLeadsByRealEstateBrokerOffice({
          variables: {
            realEstateBrokerOfficeId: datastore.data.affiliatePartnerId,
            toDate: endDate.toISOString().split('T')[0],
            fromDate: startDate.toISOString().split('T')[0],
          },
          fetchPolicy: 'no-cache'
        });
        break;
    }
  }

  const getMenuView = () => {
    switch (datastore.data.userType) {
      case 'RealEstateBroker':
        return viewType.EstateBroker;
      case 'AffiliatePartner':
        return viewType.Lead;
      case 'RealEstateBrokerOffice':
        return viewType.EstateBrokerOffice;
      default:
        return viewType.Lead;
    }
  }

  return (
    <div className="orderList MainArea">
      <header className="TopAreaMain">
        <Logo />
        <TopMenu selectedItem={TopMenuItem.LeadList} view={getMenuView()} />
      </header>
      <Grid container spacing={0}>
        <Grid xs={12}>
          <div className="contentContainer">
            <div className="Heading">Leads</div>
            <Grid container spacing={0}>
              <Grid xs={12} style={{ height: '100vh', width: '100%', borderWidth: '0px' }}>
                <div className="dateRangeContainer">
                  <p>Dato interval:</p>
                  <DatePicker
                    selected={startDate}
                    onChange={(date) => handleStartDateChange(date as Date)}
                    selectsStart
                    startDate={startDate}
                    endDate={endDate}
                    locale={"da"}
                  />
                  <p> - </p>
                  <DatePicker
                    selected={endDate}
                    onChange={(date) => handleEndDateChange(date as Date)}
                    selectsEnd
                    startDate={startDate}
                    endDate={endDate}
                    minDate={startDate}
                    locale={"da"}
                  />
                  {datastore.data.userType !== 'AffiliatePartner' && (
                    <>
                      <p>Visning: </p>
                      <select value={selectView} onChange={(e) => setSelectView(e.target.value as RealEstateBrokerView)}>
                        <option value={RealEstateBrokerView.Detailed}>Detaljeret</option>
                        <option value={RealEstateBrokerView.Grouped}>Grupperet</option>
                      </select>
                    </>
                  )}
                </div>
                <Paper elevation={0} >
                  <Card>

                    <CardContent>

                      <DataGrid
                        style={{ borderWidth: '0px' }}
                        onRowClick={handleRowClicked}
                        rows={rows}
                        columns={columns}
                        initialState={{
                          filter: {
                            filterModel: {
                              items: [],
                              quickFilterValues: [''],
                            },
                          },
                        }}
                        autoHeight={true}
                        disableColumnFilter
                        disableColumnSelector
                        disableDensitySelector
                        slots={{ toolbar: GridToolbar }}
                        slotProps={{
                          toolbar: {
                            showQuickFilter: true,
                          },
                        }}
                        sx={{
                          // disable cell selection style
                          '.MuiDataGrid-cell:focus': {
                            outline: 'none'
                          },
                          // pointer cursor on ALL rows
                          '& .MuiDataGrid-row:hover': {
                            cursor: 'pointer'
                          }
                        }}
                      />

                    </CardContent>
                  </Card>
                </Paper>
              </Grid>
            </Grid>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default LeadList;
