import React, { useRef, useState } from "react";
import "./changePassword.css";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { useMutation, gql } from "@apollo/client";
import { Navigate, useParams } from "react-router-dom";
import { datastore } from "../../datastore";

interface IProps {}

const CHANGE_PASSWORD_AFFILIATEPARTNER_GQL = gql`
  mutation setNewPasswordAffiliatePartner($affiliatePartnerId: String!, $password: String!) {
    setNewPasswordAffiliatePartner(affiliatePartnerId: $affiliatePartnerId, password: $password)
  }
`;

const CHANGE_PASSWORD_REALESTATE_BROKER_OFFICE_GQL = gql`
  mutation setNewPasswordRealEstateBrokerOffice($realEstateBrokerOfficeId: String!, $password: String!) {
    setNewPasswordRealEstateBrokerOffice(realEstateBrokerOfficeId: $realEstateBrokerOfficeId, password: $password)
  }
`;

const CHANGE_PASSWORD_REALESTATE_BROKER_GQL = gql`
  mutation setNewPasswordRealEstateBroker($realEstateBrokerId: String!, $password: String!) {
    setNewPasswordRealEstateBroker(realEstateBrokerId: $realEstateBrokerId, password: $password)
  }
`;

const ChangePassword: React.FC<IProps> = () => {
  const [showError, setShowError] = useState(false);
  const password1TextBox = useRef<HTMLInputElement | null>(null);
  const password2TextBox = useRef<HTMLInputElement | null>(null);
  const [ChangePasswordAffiliatePartner, { data: dataAffiliatePartner }] = useMutation(CHANGE_PASSWORD_AFFILIATEPARTNER_GQL);
  const [ChangePasswordRealEstateBrokerOffice, { data: dataRealEstateBrokerOffice }] = useMutation(CHANGE_PASSWORD_REALESTATE_BROKER_OFFICE_GQL);
  const [ChangePasswordRealEstateBroker, { data: dataRealEstateBroker }] = useMutation(CHANGE_PASSWORD_REALESTATE_BROKER_GQL);

  let { affiliatePartnerId, token } = useParams();

  const handleChangePasswordClicked = () => {
    const pw1 = password1TextBox.current?.value;
    const pw2 = password2TextBox.current?.value;

    if (pw1 === pw2 && pw1 !== "") {
      switch(datastore.data.userType) {
        case "RealEstateBrokerOffice":
          ChangePasswordRealEstateBrokerOffice({
            errorPolicy: "all",
            variables: {
              password: pw1,
              realEstateBrokerOfficeId: affiliatePartnerId,
            },
            context: {
              headers: {
                authorization: "Bearer " + token,
              },
            },
          });
          break;
          case "RealEstateBroker":
            ChangePasswordRealEstateBroker({
              errorPolicy: "all",
              variables: {
                password: pw1,
                realEstateBrokerId: affiliatePartnerId,
              },
              context: {
                headers: {
                  authorization: "Bearer " + token,
                },
              },
            });
          break;
          case "AffiliatePartner":
            ChangePasswordAffiliatePartner({
              errorPolicy: "all",
              variables: {
                password: pw1,
                affiliatePartnerId: affiliatePartnerId,
              },
              context: {
                headers: {
                  authorization: "Bearer " + token,
                },
              },
            });
          break;
        default:
          break;
      }
    } else {
      setShowError(true);
    }
  };

  if ((dataAffiliatePartner && dataAffiliatePartner.setNewPasswordAffiliatePartner)||(dataRealEstateBrokerOffice && dataRealEstateBrokerOffice.setNewPasswordRealEstateBrokerOffice)||(dataRealEstateBroker && dataRealEstateBroker.setNewPasswordRealEstateBroker)) {
    return <Navigate to="/login" replace />;
  } else {
    return (
      <div className="changePassword">
        <div className="logo"></div>
        <div className="background"></div>
        <div className="container">
          <div className="boxContainer">
            <div className="textContainer">
              <h1>Skift adgangskode</h1>
              {showError && <p className="warning">De indtastede adgangskoder matcher ikke - prøv igen.</p>}
              <TextField
                fullWidth
                id="password1"
                label="Ny adgangskode"
                variant="standard"
                defaultValue={""}
                type="password"
                inputRef={password1TextBox}
              />
              <br />
              <br />
              <TextField
                fullWidth
                id="password2"
                label="Bekræft adgangskode"
                variant="standard"
                defaultValue={""}
                type="password"
                inputRef={password2TextBox}
              />
              <br />
            </div>
            <div className="buttonContainer">
              <Button
                onClick={() => {
                  handleChangePasswordClicked();
                }}
                className="button"
                variant="contained"
              >
                Skift adgangskode
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }
};
export default ChangePassword;
