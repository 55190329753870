import React, { FC } from "react";
import './Logo.css'

export interface LogoProps {
  
}

const Logo: FC<LogoProps> = (props) => {
  return (
    <div className="mainMenuLogo">
        Settle
    </div>
  );
};

export default Logo;
