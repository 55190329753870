import { useQuery, gql } from "@apollo/client";
import { FC } from "react";
import { datastore } from "../datastore";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { jwtDecode } from "jwt-decode";

const GET_PARTNER = gql`
  query affiliatePartner($id: ID!) {
    affiliatePartner(id: $id) {
      id
      name
      phone
      email
      financialEmail
      cvr
      websiteUrl
      token
    }
  }
`;

export const AffiliatePartner: FC<{ children: React.ReactNode }> = ({ children }) => {
  const query = new URLSearchParams(window.location.search);
  const urlToken = query.get("t");

  if (urlToken) {
    var decodedUrlToken = jwtDecode(urlToken) as any;
    localStorage.setItem("id_token", urlToken);
    localStorage.setItem("affiliatePartnerId", decodedUrlToken.id);
    localStorage.setItem("userType", decodedUrlToken.userType);
    datastore.data.affiliatePartnerId = decodedUrlToken.id;
    datastore.data.token = urlToken;
    datastore.data.userType = decodedUrlToken.userType;
  }

  if (localStorage.getItem("id_token") !== null && datastore.data.token === "") {
    datastore.data.token = localStorage.getItem("id_token") + "";
    datastore.data.affiliatePartnerId = localStorage.getItem("affiliatePartnerId") + "";
    datastore.data.userType = localStorage.getItem("userType") + "";
  }

  const { loading, error, data } = useQuery(GET_PARTNER, {
    variables: {
      id: datastore.data.affiliatePartnerId ? datastore.data.affiliatePartnerId : localStorage.getItem("affiliatePartnerId"),
    },
    context: {
      headers: {
        authorization: "Bearer " + localStorage.getItem("id_token"),
      },
    },
  });

  if (loading) return <p></p>; //loading
  if (error) {
    console.log('error getting data from server:', error);
   // return <Error errorCode={250} />;
  }


  let root = document.documentElement;
  root.style.setProperty("--text-color", "#FFF");
  root.style.setProperty("--primary-color", "#000");
  root.style.setProperty("--secondary-color", "#FFF");

  if (!data) {
    return <>{children}</>;
  }

  //console.log('data:', data);

  //localStorage.setItem("id_token", data.affiliatePartner.token);

  const theme = createTheme({
    palette: {
      primary: {
        main: "#000",
      },
      secondary: {
        main: "#000",
      },
    },
  });

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;

};
